import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import ContentBuilder from '@theme/components/utils/ContentBuilder'
import Divider from '@theme/components/molecule/Divider'
import ProductsRecommended from '@theme/components/product/ProductsRecommended'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '~/mixins/SeoMixin'
import TopCategories from '@theme/components/category/TopCategories'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  components: {
    AppImage,
    AppTitle,
    Breadcrumbs,
    ContentBuilder,
    Divider,
    ProductsRecommended,
    ProductsVisited,
    TopCategories,
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('PageNotFound.breadcrumbs.index'),
          path: this.localePath({ name: 'index' }),
        },
      ],
      cmsContent: this.$store.state.globals.errorPages,
      links: this.$t('PageNotFound.categories') || [],
    }
  },
  head() {
    return this.getSeoHead({ yamlKey: 'PageNotFound' })
  },
  mixins: [SeoMixin],
  mounted() {
    this.$eventBus.$emit(eventDefinitions.NOT_FOUND.PAGE_VIEW)
  },
}
